// About.js
import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class TheTOGKAKenkon extends React.Component {
    render() {
        return (
            <Layout>
                <h1>The TOGKA Kenkon</h1>

                <div className="libraryImages">
                    <StaticImage src="../../images/kenkon.jpg" alt="Sanchin"/>
                </div>
                <p>When I was expelled from the IOGKF I had much stationary and many badges in stock, so not wanting to
                    throw them away but knowing I had to change something, we took the middle out of the badge. In those
                    days badges were embroidered so every student upon receiving a badge unpicked the center, and with a
                    couple of extra lines added to the letters 'IOGKF' on the stationary, it now became 'TOGKA'. All
                    that was left to do was put something back in the middle of the badge.</p>

                <p>I had to think of a design for the new badge that depicted the essence of Go Ju (hard and soft) but
                    also symbolised the spiritualism of our nature. After much thought I decided that the four basic
                    elements can do this, Earth, Air, Fire and Water. They are found in everything both material and
                    organic in our three dimensional world. And also in each element is found both the hard and the
                    soft. The next task was to find one symbol that stood for them all. After some research I found that
                    three wavy lines had symbolised the elements at sometime or other in our history.</p>

                <img src="/images/elementsImage.jpg" alt="elements"/>

                <p>At a deeper level the four elements have traits that the martial arts practitioner should use as
                    ingredients for their art. For example:</p>

                <p>Earth - strong - hard - tradition - roots</p>

                <p>Air - passive - yielding - fluidity</p>

                <p>Fire - passion - bright - engulf</p>

                <p>Water - bend to any shape, strong under pressure</p>


                <p>These are but a few examples, all with hard and soft principles. So now the TOGKA was formed with a
                    badge still representing the Kenkon.</p>

                <p>Words by Sensei Graham Ravey.</p>

                <p>To represent our martial art journey, kyu grade students wear a blank kenkon, without the symbol of
                    the elements in the middle. After passing their shodan grading, black belts wear the full TOGKA
                    Kenkon symbolising their deeper understanding of the system.</p>
                <p>The centre artwork was first designed by Rob Williams in the late eighties, and another variation by
                    Sensei Ravey can also be seen on the front of his Honbu Dojo - Kilcoy. The badge was revamped more
                    than 20 years later in 2009 by Jo Burt which is the current design used today.</p>
            </Layout>);
    }
}

export default TheTOGKAKenkon;
